@import url(https://fonts.googleapis.com/css2?family=Titillium+Web:wght@200;400;700&display=swap);
*{
 font:100%/1.5em "Titillium Web",sans-serif;
}
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*{
  margin: 0;padding: 0;box-sizing: border-box;
}
body{
  /* background: rgb(141, 112, 221); */
}
.App {
  flex-direction: column;
  display: flex;
}
/* Header */

header{
  display:flex;
  flex-direction: column;
  /* height: 8vh; */
  width: 100%;
}
.topLevelHeader{
  display:flex;
  flex-direction: row;
}
.logo{
  width: 50%;
  height: 100px;
  display:flex;
  align-items: center;
  justify-content: center;
}
.logo img{
  width: 18em;
}
.logo-text{
  font-size: 21px;
  display: flex;
  align-items: center;
  color: #4b5072;
}
.extra-style-logo{
  font-weight: 500;
  color: inherit;
  font-size: 48px;
  margin: 0 10px 13px 0;
}



.navigation {
  align-items: center;
  height: 70px;
  display: flex;
  background: #4b5072;
  /* height: 6vh; */
}
.buttonContainer{
  width: 35%;
}
nav {
  width: 60%;
  display: flex;
  list-style: none;
  /* background: green; */
  justify-content: center;
  align-items: center;
}
nav li a{
  transition:.3s ease-in-out;
  color: white;
  margin-left: 40px;
  text-decoration: none;
  font-weight: 400;
  font-size: 18px;
}
nav li a:hover{
  color: #757cc0;
}
.navButton {
  display: none !important;
  box-shadow: 3px 3px 6px 1px rgba(0, 0, 0, 0.35);
  border-radius: 4px;
  overflow: hidden;
  text-transform: uppercase;
  font-weight: 700;
  color: #fff;
  margin: 0 auto;
  width: 190px;
  height: 32px;
  background: #548660;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: .2s ease-in-out;
}
.navButton:hover{
  cursor: pointer;
  box-shadow: 3px 3px 6px 1px rgba(0, 0, 0, 0.55);
}
.navButton img {
  margin-left: -15px;
  width: 48px;
}

/* Main */

.PszokApp{
  display: flex;
  height: calc(100vh - 170px);
}
.toggleBtn{
  display: none;
}
.SearchWithList {
  height: inherit;
  width: 32%;
  /* background: rgb(141, 112, 221); */
  background: #565a7d;
  overflow-y: scroll;
  overflow-x: hidden;

}
.list {
  min-height: inherit;
  /* overflow-y: scroll; */

}
.map{
  width: 68%;
  height: inherit;
}
.list .pszok {
  font-size: 16px;
  color: #fff;
  list-style-type: none;
  margin-top: 1em;
  /* background: rgb(113, 81, 201); */
  background: #4b5072;
  width: 90%;
  padding: 1em;
  margin: 1em  auto 1em auto;
  border-radius: 6px;
  box-shadow: 2px 2px 6px 0 rgba(0, 0, 0, 0.2);
}
.list .pszok p,
.list .pszok a,
.list .pszok button,
.list .pszok span{

  font-weight: 200;
  -webkit-filter: drop-shadow(1px 1px 4px rgba(0, 0, 0, 0.6));
          filter: drop-shadow(1px 1px 4px rgba(0, 0, 0, 0.6));
}
.list .pszok:first-child {
  margin-top: 2em;
}
.list .pszok button {
  background: none;
  outline: none;
  border: none;
  color: inherit;
}
.list .pszok a {
  color: inherit;
  text-decoration: none;
}
.list .pszok a:hover,
.list .pszok button:hover {
  text-decoration: underline;
  cursor: pointer;
}

.inputsContainer{
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  background: inherit;
  top: 0;
  padding: .4em 5%;
  min-height: 10vh;
  z-index: 101;
}
.checkmarks {
  margin-top: .6em;
  display: flex;
  justify-content: space-between;
}
.checkmarks .containerCheck,
.checkmarks label {
  border-radius: 4px;
  display: flex;
  width: 47.5%;
  box-shadow: 2px 2px 6px 0 rgba(0, 0, 0, 0.2);
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.checkmarks label:hover{
  cursor: pointer;
}
.inputSearch {
  position: relative;
  display: flex;
  box-shadow: 2px 2px 6px 0 rgba(0, 0, 0, 0.25);

}
.clearBtn {
  font-size: 1.1em;
  color: #4b5072;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  background: #fff;
  border: none;
  outline: none;
  right: 3.5em;
  position: absolute;
}
.clearBtn:hover {
  cursor: pointer;
}
/* .checkmarks label input,
.inputSearch input,button{
  height: 3em;
} */
.inputsContainer .inputSearch input[type="text"]{
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  width:calc(100% - 2.6em);
  padding:0 1%;
  outline: none;
}
.inputSearch input[type="text"]::-webkit-input-placeholder {
  color: black;
  font-size: 16px;
}
.inputSearch input[type="text"]:-ms-input-placeholder {
  color: black;
  font-size: 16px;
}
.inputSearch input[type="text"]::placeholder {
  color: black;
  font-size: 16px;
}
.inputsContainer .inputSearch .buttonSearch {
  width: 2.6em;
  box-shadow: 1px 1px 2px 0 rgba(255, 255, 255, 0.15);
  transition: all .3s ease;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.inputsContainer .inputSearch .buttonSearch:hover{
  cursor: pointer;
  box-shadow: 2px 2px 7px 0 rgba(8, 7, 7, 0.35);

}
.inputsContainer .inputSearch input[type="text"],
.inputsContainer .inputSearch .buttonSearch {
  height: 2.6em;
}

.checkmarks .containerCheck {
  height: 3em;
  align-items: center;
  justify-content: space-between;
  padding-right: 3%;
}
.checkmarks .containerCheck:first-child {
  background: #FFD329;
}
.checkmarks .containerCheck:last-child {
  background: #2C5EA9;
}
.checkmarks .containerCheck .imgWithText {
  display: flex;
  align-items: center;
}
.checkmarks .containerCheck .imgWithText p {
  font-weight: 700;
  font-size: 18px;
  color: #FFF;
}
.checkmarks .containerCheck .imgWithText .pszokImg {

  height: 32px;
  width: 32px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 200%;
}
.checkmarks .containerCheck:nth-of-type(1) .pszokImg {
  background-image: url(/static/media/pinezka_pszok.2d520d0c.svg);
}
.checkmarks .containerCheck:nth-of-type(2) .pszokImg {
  background-image: url(/static/media/pinezka_elektro.3c9ff6ab.svg);
}

/* .img {
  background-image: url('./images/pinezka_elektro.svg');
} */
.buttonSearch{
  background: #4b5072;
  border: none;
  outline: none;
  background-image: url(/static/media/lupka.43a685ac.svg);
  width: 2.6em;
  height: 2.6em;
  background-size: 150%;
  background-position:center;
  
}
.return {
  background: #4b5072;
  width: 100%;
  height: 2.6em;
  margin-top: 1em;
  box-shadow: 2px 2px 6px 0 rgba(8, 7, 7, 0.2);
  border: none;
  color: white;
  font-size: 16px;
  transition: all .2s ease;
}
.return:hover{
  cursor: pointer ;
  box-shadow: 2px 2px 7px 0 rgba(8, 7, 7, 0.4);
  
}
.hide {
  display: none;
}
/* Map */

.cluster{
  /* font-weight: 400; */
  color: white;
  background: #565b7e;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 12px;
  justify-content: center;
  border-radius: 50%;
  padding: 1.2em;
}
.cluster:hover{
  cursor: pointer;
}
.Marker {
  z-index: 808;
  width: 3em;
  height: 3em;
  /* padding: 2.5em; */
  /* height: 50px; */
  border-radius: 50%;
  background: none;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.Marker img {
  width: 10em;
  margin: -5em;
}
.Marker:hover {
  cursor: pointer;
}
/* .pszokSelected {
  background-image: url('./images/pszok_selected.svg') !important;
}
.elektroSelected {
  background-image: url('./images/elektro_selected.svg') !important;
}
.pszokTest {
  background-image: url('./images/pinezka_pszok.svg');
}
.elektroTest {
  background-image: url('./images//pinezka_elektro.svg');
}
.selectedPszok{
  background: red !important; 
}
.selectedElektro{
  background: blue !important; 
} */
/* .pszokTest,
.elektroTest {
  background-repeat: no-repeat;
  background-position: center;
  background-size: 200%;
} */

/* Footer */

footer {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.reportPoint{
  height: 70px;
  color: white;
  width: 100%;
  background: #565b7e;
}
.reportPoint a {
  text-decoration: none;
  color: #fff;
}
.organizers{
  height: 294px;
  width: 100%;
  grid-gap: 100px;
  gap: 100px;
}
.organizerContainer{
  display: flex; 
  flex-direction: column;
  align-items: center;
  grid-gap:24px;
  gap:24px;
  text-transform: uppercase;
  color: #a0a2a5;
}
.findUs{
  height: 70px;

  width: 100%;
 background:  #4b5072;
}
.author{
  color: #a0a2a5;
}
.author img {
  height: 40px;
}
.reportPoint,
.organizers,
.findUs,
.author {
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex{
  display: flex;
}
.space{
  margin: 0 5px;
}
.author a:hover,
.findUs:hover,
.reportPoint:hover{
  cursor: pointer;
}

@media screen and (max-width: 1440px) {
  .checkmarks{
    flex-direction: column;
  }
  .checkmarks .containerCheck {
    width: 100%;
    height: 2.4em;
    justify-content: space-between;
    padding: 0 4%;
  }
  .checkmarks .containerCheck:last-child {
    margin-top: .6em;
  }
}

@media screen and (max-width: 1024px) {
    .clearBtn {
      /* left: 80%; */
    }
  .logo{
    margin-left: 1em;
  }
  .navigation{
    justify-content: flex-end;
  }
  .buttonContainer{
    display: none;
  }
  .PszokApp {
    position: relative;
  }
  .map{
    width: 100%;
  }
  .SearchWithList {
    position: absolute;
    width: 22em;
    /* left: -22em; */
    left: 0;
    top: 0;
    z-index: 8;
    transition:left .4s ease;
  }
  .inputsContainer {
    z-index: 10;
  }
  .list {
    position: relative;
  }
  .toggleBtn {
    transition:left .4s ease;

    display: block;
    position: absolute;
    left: 23em;
    border-radius: 4px;
    width: 5.5em;
    top: 1em;
    z-index: 11;
    color: white;
    border: none;
    box-shadow: 2px 2px 3px 0 rgba(8, 7, 7, 0.4);
    background: #4b5072;
  }
  .toggleBtn:hover {
    cursor: pointer;
  }
  .toggleBtn div {
    padding: .2em 0em .2em .4em;
    align-items: center;
    justify-content: center;
    display: flex;
  }
  .toggleBtn div img {
    width: 24px;
  }
  .openBtn {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg)
  }
  .hideList {
    left:-22em !important;
  }
  .hideBtn {
    left: 1em !important;
  }
}
#hamburger{
  display: none;
}
@media screen and (max-width: 800px) {
  .PszokApp{
    height: calc(100vh - 50px);
  }
  #hamburger {
    position: absolute;
    z-index: 102;
    right: 2em;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    opacity: 0.8;
    cursor: pointer;
    transition: opacity 0.25s linear;
    width: clamp(1.5rem, 2vw + 1.1rem, 6rem);
    height: clamp(2rem, 1.4vw + 1.7rem, 5rem);
    display: flex;
    align-items: center;
  }
  
  #hamburger div,
  #hamburger div:after,
  #hamburger div:before {
    background-color: #4b5072;
    border-radius: 10px;
    width: clamp(1.5rem, 2vw + 1.1rem, 6rem);
    height: clamp(0.2rem, 0.3vw + 0.1rem, 0.8rem);
    transition: all 0.15s linear;
  }
  
  #hamburger div:before,
  #hamburger div:after {
    content: "";
    position: absolute;
  }
  
  #hamburger div:before {
    -webkit-transform: translateY(-200%);
            transform: translateY(-200%);
  }
  
  #hamburger div:after {
    -webkit-transform: translateY(200%);
            transform: translateY(200%);
  }
  
  #hamburger.open div {
    background: transparent;
  }
  
  #hamburger.open div:before {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
  }
  
  #hamburger.open div:after {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
  }
  
  .topLevelHeader {
    height:50px;
  }
  .logo{
    width: auto;
    height: auto;
  }
  .logo a{
    width: auto;
    height: auto;
  }
  .logo a img {
    margin-top: 10px;
    width: 200px;
  }
  .logo-text{
    display: none;
  }
  .SearchWithList{
    width: 22em;
  }
  header{
    position: relative;
  }
  .navigation{
    background: #fffffff2;
    top: -100vh;
    transition:top .4s ease;
    position: fixed;
    width: 100%;
    
    justify-content: center;
    height: 100vh;
    z-index: 101;
  }
  .showNav {
    top: 0;
  }
  .navigation nav {
    height: 50%;
    width: 100%;
    flex-direction: column;
    justify-content: space-around;
  }
  .navigation nav li > a {
    color: #4b5072be;
    font-size: 1.8em;
    margin: 0;
  }
  .toggleBtn{
    left: 23em;
  }
  .hideList {
    left:-22em !important;
  }
  .hideBtn {
    left: 1em !important;
  }
  .organizers {
    padding: 4em 0;
    grid-gap:3em;
    gap:3em;
    height: auto;
    display: flex;
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 480px) {
  .clearBtn {
    /* left: 75%; */
  }
  .SearchWithList{
    width: 19em;
  }
  .toggleBtn{
    width: 6em;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    top: 3em;
    left: 17.7em;
  }
  .openBtn {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }
  .hideList{
    left:-19em !important;

  }
  .hideBtn {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    left: 1em !important;
  }
  .organizers {
    padding-left: 4%;
    padding-right: 4%;
  }
  .organizers img {
    width: 100%;
  }
}



/* Custom checkmarks */
.containerCheck {
  position: relative;
  cursor: pointer;
}
.containerCheck input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  right: .5em;
}
.checkmark {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  right: .5em;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 4px;
}

.containerCheck:hover input ~ .checkmark {
  background-color: #fff;
}

.containerCheck input:checked ~ .checkmark {
  background-color: #fff;
}

.containerCheck .checkmark:after {
  content: "";
  position: absolute;
  display: none;
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid #4b5072;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.containerCheck input:checked ~ .checkmark:after {
  display: block;
}
.bold {
  font-weight: 400 !important;
}
.margin {
  display: block;
  margin-top:.9em;
}
.underline {
  text-decoration: underline !important;
}
